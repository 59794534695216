
*{
   padding: 0;
   margin: 0;
   border: 0;
 
 }
 *,*:before,*:after{
   -moz-box-sizing: border-box;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
 }
 
 :focus,:active{outline: none;}
 a:focus,a:active{outline: none;}
 
 
 nav,footer,header,aside{display: block;}
 :root{
   --green:#55CA83;
   --green-d:#3db06b;

   --black:#373737;
   --gray:#F9F9F9;
   --green-l:#8EE2AF;
   
 }
 html,body{
   height: 100%;
   width: 100%;
   line-height: normal;
   font-size: 20px;
   -ms-text-size-adjust: 100%;
   -moz-text-size-adjust: 100%;
   -webkit-text-size-adjust: 100%;
   color: var(--black);
 
   @media (max-width:1279px) {
   font-size: 16px;
     
   }
   @media (max-width:767px) {
     font-size: 14px;
       
     }
 }
 
 
 input,button,textarea{font-family: inherit; }
 
 
 
 input::-ms-clear{display: none;}
 button{cursor: pointer;
   padding: 0;
   border: none;
   font: inherit;
   color: inherit;
   background-color: transparent;}
 button::-moz-focus-inner {padding:0;border:0;}
 a, a:visited{text-decoration: none;}
 a:hover{text-decoration: none;}
 ul li{list-style: none;}
 img{vertical-align: top;}
 
 h1,h2,h3,h4,h5,h6{font-size: inherit;font-weight: 400;}
 html{

   overflow-x: hidden;
 };
 
 #root{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   min-height: 100%;
   width: 100%;
   max-width: 100vw;
   overflow: hidden;
 }
 .main{
   width: 100%;
   flex: 1 1 auto;
 }
 
 ._ibg {
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   position: relative;
 }
 
 ._ibg img {
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 }
 .mainCnt{
   width: 100%;
   @media (min-width:1280px) {
     padding: 0 74px;
 
   }
 }
 .container {
   max-width: 1772px;
   width: 100%;
   padding-left: 86px;
   padding-right: 86px;
 
   margin: 0 auto;
   @media (max-width:1720px) {
     padding-left: 60px;
     padding-right: 60px;
   }
   @media (max-width:1279px) {
     padding-left: 42px;
     padding-right: 42px;
     }
     @media (max-width:767px) {
       padding-left: 12px;
       padding-right: 12px;
         
       }
 }
 
 .welcomeImgT{
   img{
     @media (max-width:1279px) {
       max-width: 99px;
       
       max-height: 99px;
     }
 
   }
   
 }
 .welcomeImgR{
   img{
     @media (max-width:1720px) {
       max-width: 264px;
       max-height: 264px;
     }
     @media (max-width:1279px) {
       max-width: 246px;
       max-height: 246px;
       }
       @media (max-width:767px) {
         max-width: 197px;
         max-height: 197px;
           
         }
 
   }
   
 }
 .welcomeImgB{
   img{
     @media (max-width:1279px) {
       max-width: 229px;
       max-height: 229px;
     }
 
   }
   
 }
 .welcomeImgL{
   img{
     @media (max-width:1279px) {
       min-width: 229px;
       min-height: 229px;
     }
 
   }
 }
 .btn{
   border-radius: 30px;
   background: linear-gradient(0deg, #2A9755 0%, #46EB87 99.99%);
   box-shadow: 0px 0px 34px 0px rgba(70, 235, 135, 0.46);
   padding: 28px 30px;
   line-height: 0.8;
   animation: opacAnt 0.5s forwards;
   position: relative;
   overflow: hidden;
   &::after{
     content: '';
     position: absolute;
     background: #ffffff73;
     height: 2px;
     width: 100%;
     bottom: 0px;
     left: -100%;
     transform: rotate(90deg);
     
     transition: all 0.5s;
   }
   &.btnBonus{
     border-radius: 20px;
     background: #fff;
     padding: 25px 30px;
     @media (max-width:767px) {
       padding: 25px 10px;
 
     }
     &:hover{
       color:#fff;
     }
   }
   &:hover{
     background: linear-gradient(272deg, #2A9755 0%, #46EB87 100%);
     animation: opacAn 0.5s forwards;
 
     &::after{
       transform: rotate(180deg);
       left: 100%;
       
     }
   }
 
  
 }
 @keyframes opacAn {
   0%{
     opacity: 0.7;
   }
 
   100%{
     opacity: 1;
   }
 }
 @keyframes opacAnt {
   0%{
     opacity: 0.7;
 
   }
 
   100%{
     opacity: 1;
   }
 }
 
 .bgImg{
   width: 100%;
   height: 100%;
   object-fit: cover;
 }
 .bgImgBon{
   animation: bgImg 15s infinite;
 
 }
 .notFoundImg{
   animation: notFoundImgAn 10s infinite;
   transform-origin: bottom;
 }
 @keyframes notFoundImgAn {
   0%{
     transform: rotate(0);
   }
   
   50%{
     transform: rotate(-20deg);
 
   }
   100%{
     transform: rotate(0);
       
   }
 }
 // .bgImgCnt{
 //   position: absolute;
 //   z-index: 1;
 //   width: 0%;
 //   height: 0%;
 //   bottom: 0;
 //   right: 0;
 //   background-color: rgba(249, 249, 249, 0.974);
 //   animation: bgImgCnt 3s infinite ;
 // }
 // @keyframes bgImgCnt {
 //   0%{
 //     width: 0%;
 //     height: 0%;
 //   }
 //   50%{
 //     width: 0%;
 //     height: 0%;
 //   }
 //   60%{
 //       height: 100%;
 //       width: 100%;
 //   }
 //   70%{
 //     width: 0%;
 //     height: 0%;
 //     bottom: auto;
 //     right: auto;
 //     top: 0;
 //     left: 0;
 //   }
 //   100%{
 //     width: 0%;
 //     height: 0%;
 //     bottom: auto;
 //     right: auto;
 //     top: 0;
 //     left: 0;
 //   }
 // }
 @keyframes bgImg {
   0%{
     width: 100%;
   }
   25%{
     width: 150%;
     height: 200%;
   }
   50%{
     width: 150%;
     height: 100%;
   }
   75%{
     width: 200%;
     height: 200%;
   }
   100%{
       height: 100%;
   }
 }
 
 .bgImgFly{
   transform:translate(-50%,-50%) scale(1) rotate(0deg);
   object-fit: cover;
   animation: bgImgFly 10s infinite;
   transition: all 1s;
 }
 .flyObj{
   box-shadow: 0px 4px 63px 0px rgba(0, 0, 0, 0.13);
   animation: objFly 8s infinite;
   transition: left 0.5s, right 0.5s;
   left: -5%;
   transform: scale(1) rotate(-5deg);
 
   img{
     transition: transform 0.5s;
   }
   &:hover{
     left: -3%;
     img{
       transform:translateX(10px) scale(1.1) rotate(-10deg);
     }
   }
 }
 .flyObjR{
   animation: objFlyR 8s infinite 1s;
   right: -5%;
   left: auto;
   transform: scale(1) rotate(5deg);
   &:hover{
     right: -3%;
     left: auto;
     
   }
 }
 @keyframes objFly {
   0%{
     transform: scale(1) rotate(-5deg);
   }
   
   75%{
     transform: scale(0.8) rotate(-10deg);
   }
   100%{
     transform: scale(1) rotate(-5deg);
 
   }
 }
 @keyframes objFlyR {
   0%{
     transform:scale(1) rotate(5deg);
   }
   
   75%{
     transform:scale(0.8) rotate(10deg);
   }
   100%{
     transform:scale(1) rotate(5deg);
 
   }
 }
 @keyframes bgImgFly {
   0%{
     transform:translate(-50%,-50%) scale(1) rotate(0deg);
   }
   
   40%{
     transform:translate(-55%,-45%) scale(0.8) rotate(30deg);
 
   }
   80%{
     transform:translate(-45%,-55%) scale(0.9) rotate(10deg);
 
   }
   100%{
     transform:translate(-50%,-50%) scale(1) rotate(0deg);
 
   }
 }
 
 .menulangs{
   border-radius: 5px;
   background: var(--green);
   box-shadow: 0px 4px 63px 0px rgba(0, 0, 0, 0.13);
 }
 
 
 .footer{
   border-radius: 40px 40px 0px 0px;
   background: var(--gray);
 }
 
 
 .mtop{
  margin-top:36px;

  //  margin-top: 99px;
  //  @media (max-width:1720px) {
  //    margin-top: 160px;
  //  }
  //  @media (max-width:1279px) {
  //    margin-top: 120px;
  //    }
     @media (max-width:767px) {
       
       margin-top:41px;
       }
 
 }
 
 // .slider{
 //   .swiper{
 //     padding: 100px 0;
 //     width: calc(100% - 200px);
 //   }
 //   .swiper-wrapper{
 //   }
 //   .swiper-slide{
 
 //     display: flex;
 //     align-items: center;
 //     justify-content: center;
 //     .slide{
 //       filter: blur(8px);
 //       transition: all 0.2s;
 //       transform: scale(0.9);
 //     }
     
 
 //   }
 //   .swiper-slide-prev{
 //     .slide{
 //       transform: scale(0.9) translateX(100px);
 //     }
 //   }
 //   .swiper-slide-active{
 //     .slide{
 //       transform: scale(1);
 //       filter: none;
 //     }
    
 //     z-index: 2;
 //   }
 //   .swiper-slide-next{
 //     .slide{
 //       transform: scale(0.9) translateX(-100px);
 //     }
 //   }
 // }
 .slider-cnt{
   padding-left: 0px;
   padding-right: 0px;
 }
 .slider{
   
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   @media (max-width:767px) {
   grid-template-columns: repeat(3, 1.5fr);
     
   }
 }
 .slide{
   min-width: 600px;
   @media (max-width:1720px) {
     min-width: 530px;
 
     
   }
   @media (max-width:767px) {
     min-width: 90vw;
 
     
   }
   text-shadow: 0px 0px 1px #bdbdbd;
   transition: all 0.5s;
   // flex: 0 0 33.333%;
   // @media (max-width:1720px) {
   //   flex: 0 0 45%;
   // }
   // @media (max-width:1279px) {
   //   flex: 0 0 55%;
 
     
   // }
   // @media (max-width:767px) {
   //   flex: 0 0 90%;
   // }
   .slideCnt-cnt{
     box-shadow: 0px 4px 63px 0px rgba(0, 0, 0, 0.13);
     filter: blur(8px);
 
     transition: all 0.3s;
   }
   .slideCnt-cnt{
     
     .bonusImg{
       transition: opacity 0.2s;
       opacity: 0;
       animation: bgImgFlyBonus 8s infinite 1s;
     }
 
   
     &.prev{
       transform: scale(0.9) translateX(100px);
      
       
     }
     &.next{
       transform: scale(0.9) translateX(-100px);
       
       
     }
   }
   
   &.active{
     z-index: 2;
     
     
     .slideCnt-cnt{
       transform: scale(1) translateX(0);
       filter: none;
       .bonusImg{
         opacity: 1;
       }
     }
   }
   
 }
 .directionRtl{
    direction:rtl;
    flex-wrap:wrap;
 }
 
 @keyframes bgImgFlyBonus {
   0%{
     transform:translate(0,0) scale(1) rotate(0deg);
   }
   
   40%{
     transform:translate(10px,-10px) scale(0.8) rotate(30deg);
 
   }
   80%{
     transform:translate(-20px,20px) scale(0.9) rotate(10deg);
 
   }
   100%{
     transform:translate(0,0) scale(1) rotate(0deg);
 
   }
 }
 

 .loader.active{
   
   .loaderW{
     transform:translateX(-100%);
     opacity: 0;
     animation: loaderWAnim 1s forwards 0.7s;
   }
 }
 .loader.active.langsCh{
   
   .loaderW{
     transform:translateX(-100%);
     opacity: 0;
     animation: loaderWAnim 1s forwards;
   }
 }
 @keyframes loaderWAnimOpac {
   0%{
     opacity: 1;
   }
   50%{
     opacity: 0.5;
     
 
   }
   100%{
     opacity: 1;
     
 
   }
 }
 @keyframes loaderWAnim {
   0%{
     transform:translateX(-100%);
     opacity: 0;
   }
   20%{
     opacity: 0;
     transform:translateX(-100%);
 
   }
   60%{
     opacity: 1;
     
 
   }
   100%{
     transform:translateX(0%);
     opacity: 1;
 
   }
 }
 
 
 .footer img{
   width: auto;
 }
 
 .liSpan{
   position: relative;
   &::after{
     position: absolute;
     content: '';
     width: 5px;
     height: 5px;
     border-radius: 100%;
     background-color: var(--black);
     top: 10px;
     transform: translateY(-50%);
     left: -15px;
   }
 }
 
 
 .bonusImgT,.bonusImgR{
   animation: bgImgFlyBonus 50s infinite;
 }
.drop-shadowNF{
  text-shadow: 1px 1px 2px rgb(228, 228, 228);
}
.text-nowrap{
  text-wrap:nowrap
}

