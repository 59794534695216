@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
	font-family: 'unbounded';
	src: url('./fonts/static/Unbounded-Black.ttf') format('TrueType');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'unbounded';
	src: url('./fonts/static/Unbounded-ExtraBold.ttf') format('TrueType');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'unbounded';
	src: url('./fonts/static/Unbounded-Bold.ttf') format('TrueType');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'unbounded';
	src: url('./fonts/static/Unbounded-SemiBold.ttf') format('TrueType');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'unbounded';
	src: url('./fonts/static/Unbounded-Medium.ttf') format('TrueType');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'unbounded';
	src: url('./fonts/static/Unbounded-Regular.ttf') format('TrueType');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'unbounded';
	src: url('./fonts/static/Unbounded-Light.ttf') format('TrueType');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'unbounded';
	src: url('./fonts/static/Unbounded-ExtraLight.ttf') format('TrueType');
	font-weight: 200;
	font-style: normal;
}

body{
   font-family: 'unbounded';
}